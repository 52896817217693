export const fetchWithTimeout = async (
  url: string,
  timeout = 2000,
  customHeaders?: { [key: string]: string }
) => {
  const ctrl = new AbortController();
  const { signal } = ctrl;

  let abortRequest: any;
  const settings = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...customHeaders
    },
    signal
  };

  // set timeout
  const timeOut = new Promise((resolve) => {
    abortRequest = setTimeout(async () => {
      if (ctrl) {
        ctrl.abort();
      } // abort fetch request
      console.error(`cbgCompPack:: request cancelled ${url}`);
      resolve('timeout');
    }, timeout);
  });

  const fetchData = fetch(url, settings)
    .then((resp) => resp.json())
    .then((json) => json)
    .catch((err) => {
      throw err;
    })
    .finally(() => {
      clearTimeout(abortRequest);
    });

  const result = await Promise.race([timeOut, fetchData]);
  return result;
};
