const cbgCompScript = document.getElementById('cbgCompPack');

const scriptSrc = cbgCompScript && cbgCompScript.getAttribute('src');
const initScriptUrl = scriptSrc;
const BASE_URL = initScriptUrl.replace('cbgCompPack.js', '');

const getEnvAlias = (baseUrl: string) => {
  if (baseUrl.includes('localhost')) {
    return 'local';
  }
  if (baseUrl.includes('dev')) {
    return 'dev';
  }
  if (baseUrl.includes('tst')) {
    return 'tst';
  }
  if (baseUrl.includes('stg')) {
    return 'stg';
  }
  return 'prd';
};

const APIEnv = {
  local: 'dev',
  dev: 'dev',
  tst: 'tst',
  stg: 'stg',
  prd: 'prd'
};

const APIEnvMedia = {
  local: 'dev',
  dev: 'dev',
  tst: 'tst',
  stg: 'stg',
  prd: 'live'
};

const envAlias = getEnvAlias(BASE_URL);
const apiEnv = APIEnv[envAlias];
const apiEnvMedia = APIEnvMedia[envAlias];
const isDev = APIEnv[envAlias] === 'dev';
const isStg = APIEnv[envAlias] === 'stg';

export { envAlias, apiEnv, apiEnvMedia, isDev, isStg, BASE_URL };
