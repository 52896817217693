import {
  envAlias,
  BASE_URL,
  apiEnv,
  apiEnvMedia,
  isDev,
  isStg
} from 'config/env';

const API_URL = `https://api-eu.carlsbergwebservices.com/ct/${apiEnv}`;
export const STATIC_ASSETS_URL = `${BASE_URL}static/`;
export const GEO_API_URL = `${API_URL}/ct-geoip-service-be/api/whereami`;
export const POLICIES_BASE_URL = 'https://compliance.carlsberggroup.com';
export const API_BASE_URL = `${API_URL}/ct-serv-legal-pol-be/api/projects`;
export const AGEGATE_CONFIG_URL = `age-gateways`;
export const MEDIA_URL = `https://cx-media-fe.${apiEnvMedia}.cx-apps.io`;
export const AUDIT_URL = `${API_URL}/ct-report-service-be/api/audit/cookie-consent`;
